import * as React from 'react';

import SearchAppBar from "../components/SearchAppBar";
import InspectionsList from "../components/InspectionsList";
import AddNewIinspectionFAB from "../components/AddNewIinspectionFAB";
import NewRequest from "../components/NewRequest";


function Home() {


   return (
      <>

         <NewRequest/>

         {/*<SearchAppBar/>*/}
         {/*<InspectionsList/>*/}
         {/*<AddNewIinspectionFAB/>*/}
      </>
  )
}

export default Home



import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
   GridRowModes,
   DataGrid,
   GridToolbarContainer,
   GridActionsCellItem,
} from '@mui/x-data-grid';
import {
   randomCreatedDate,
   randomTraderName,
   randomUpdatedDate,
   randomId,
} from '@mui/x-data-grid-generator';
import {useContext} from "react";
import ECContext from "../../context/inspections/ECContext";

const initialRows = [
/*
   {
      id: randomId(),
      comment: "комментарий к доп. расходу",
      sum: 212341,

   },
   {
      id: randomId(),
      comment: "комментарий к доп. расходу",
      sum: 987641,
   },
*/
];

function EditToolbar(props) {
   const { setRows, setRowModesModel } = props;

   const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { id, sum: '', comment: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
         ...oldModel,
         [id]: { mode: GridRowModes.Edit, fieldToFocus: 'sum' },
      }));
   };

   return (
      <GridToolbarContainer>
         <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Добавить доп. расход
         </Button>
      </GridToolbarContainer>
   );
}

EditToolbar.propTypes = {
   setRowModesModel: PropTypes.func.isRequired,
   setRows: PropTypes.func.isRequired,
};

export default function AdditionalExpencesTransporter1() {

   const { dispatch } = useContext(ECContext)

   const [rows, setRows] = React.useState(initialRows);
   const [rowModesModel, setRowModesModel] = React.useState({});
   const [totalExpensesSum, setTotalExpensesSum] = React.useState(0);


   React.useEffect(() => {

      recalculateExpensesSum()

   }, [rows]);


   const recalculateExpensesSum = () => {
      let total = 0;
      rows.forEach((row) => {
        total += Number.isNaN(row.sum) ? 0 : Number(row.sum)
      })

console.log('Rows:')
console.log(rows)

      //setTotalExpensesSum(total)

      dispatch({ type: 'SET_TRANSPORTER1_ADDITIONAL_EXPENSES', payload: total })
   }

   const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
   };

   const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true;
   };

   const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
   };

   const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
   };

   const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
   };

   const handleCancelClick = (id) => () => {
      setRowModesModel({
         ...rowModesModel,
         [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
         setRows(rows.filter((row) => row.id !== id));
      }
   };

   const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
   };

   const columns = [
      { field: 'comment', headerName: 'Комментарий', width: 400, editable: true },
      { field: 'sum', headerName: 'Сумма', type: 'number', width: 150, editable: true },
      {
         field: 'actions',
         type: 'actions',
         headerName: '',
         width: 100,
         cellClassName: 'actions',
         getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
               return [
                  <GridActionsCellItem
                     icon={<SaveIcon />}
                     label="Сохранить"
                     onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                     icon={<CancelIcon />}
                     label="Отмена"
                     className="textPrimary"
                     onClick={handleCancelClick(id)}
                     color="inherit"
                  />,
               ];
            }

            return [
               <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
               />,
               <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
               />,
            ];
         },
      },
   ];

   return (
      <Box
         sx={{
            height: 250,
            width: '50%',
            '& .actions': {
               color: 'text.secondary',
            },
            '& .textPrimary': {
               color: 'text.primary',
            },
         }}
      >
         <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
               Toolbar: EditToolbar,
            }}
            componentsProps={{
               toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}

            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter={true}
         />
      </Box>
   );
}
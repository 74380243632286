import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import InspectionsEdit from "./InspectionEdit";
import InspectionEdit from "./InspectionEdit";
import {useContext} from "react";
import ECContext from "../context/inspections/ECContext";

function createData(id, name/*, calories, fat, carbs, protein*/) {
   return { id, name/*, calories, fat, carbs, protein*/ };
}

const rows = [
   createData(1, 'Test | New York Montgomery County, UNITED STATES OF AMERICA'),
   createData(2, "Connor O'keefe | Heggen Street, 1440, Resco, Hudson Saint Croix County Wisconsin 54016-9303, UNITED STATES OF AMERICA"),
   createData(3,"Rebecca and Jesse Gross | 510 4th Street, Luck WI 54853"),
   createData(4,"Maureen McGowan | 545 10th Ave N, South St. Paul, MN 55075"),
   createData(5,"Karen Eichman | 13204 Upton Ave S, Burnsville, MN 55337"),
   createData(6,"Melissa Terry | 4401/4405 5th Ave South, Minneapolis, MN 55419"),
   createData(7, "NB Enterprises LLC (Nichole Borell) | 5538 Chicago Ave, Minneapolis, MN 55417"),
   createData(8,"John Booth | 710 Dundee Ave, River Falls, WI 54022, USA"),
   createData(9,"Tarry Town/ Carol Stranski | 1982 Arkwright Maplewood mn"),

/*   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
   createData('Eclair', 262, 16.0, 24, 6.0),
   createData('Cupcake', 305, 3.7, 67, 4.3),
   createData('Gingerbread', 356, 16.0, 49, 3.9),
   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
   createData('Eclair', 262, 16.0, 24, 6.0),
   createData('Cupcake', 305, 3.7, 67, 4.3),
   createData('Gingerbread', 356, 16.0, 49, 3.9),
   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
   createData('Eclair', 262, 16.0, 24, 6.0),
   createData('Cupcake', 305, 3.7, 67, 4.3),
   createData('Gingerbread', 356, 16.0, 49, 3.9),
   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
   createData('Eclair', 262, 16.0, 24, 6.0),
   createData('Cupcake', 305, 3.7, 67, 4.3),
   createData('Gingerbread', 356, 16.0, 49, 3.9),
   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
   createData('Eclair', 262, 16.0, 24, 6.0),
   createData('Cupcake', 305, 3.7, 67, 4.3),
   createData('Gingerbread', 356, 16.0, 49, 3.9),*/

];

export default function InspectionsList() {

   const { dispatch } = useContext(ECContext)

   const openInspection = (event) => {
      const inspectionId = event.target.parentElement.id
      console.log(`Trying to open Dialog!`)

      dispatch({ type: 'SET_SHOW_INSPECTION_DIALOG', payload: {showDialog: true} })
   }





   return (

      <>

         <TableContainer component={Paper}>
         <Table aria-label="Inspections">
            <TableBody>
               {rows.map((row) => (
                  <TableRow
                     id={row.id}
                     key={row.name}
                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     onClick={openInspection}
                  >
                     <TableCell align="right">{row.id}</TableCell>
                     <TableCell component="th" scope="row">{row.name}</TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
         </TableContainer>


         <InspectionEdit/>


      </>
   );
}
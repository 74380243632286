import * as React from 'react';
import {
   Accordion, AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, CircularProgress,
   Dialog,
   FormControl, FormControlLabel, FormGroup, FormLabel,
   InputLabel,
   MenuItem, Radio, RadioGroup,
   Select,
   Slide, Stack, TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PhotoCamera} from "@mui/icons-material";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import General from "./inspection_blocks/general";
import Jobsite from "./inspection_blocks/jobsite";
import Elevations from "./inspection_blocks/elevations";
import Palletes from "./inspection_blocks/palletes";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NewTransporter from "./new_request_blocks/new_transporter";
import NewTransporterContract from "./new_request_blocks/new_transporter_contract";
import NewClientContract from "./new_request_blocks/new_client_contract";
import Cargos from "./inspection_blocks/cargos";
import AdditionalExpences from "./inspection_blocks/additional_expences";
import TransportAuto from "./inspection_blocks/transport_auto";
import TransportZhd from "./inspection_blocks/transport_zhd";
import TransportSea from "./inspection_blocks/transport_sea";
import TransportAvia from "./inspection_blocks/transport_avia";
import AdditionalExpencesTransporter1 from "./inspection_blocks/additional_expences_transporter1";


function sleep(delay = 0) {
   return new Promise((resolve) => {
      setTimeout(resolve, delay);
   });
}

export default function NewRequest() {


   const [expanded, setExpanded] = React.useState('panel_segment1');
   const [expanded2, setExpanded2] = React.useState('');
   const [expandedClient, setExpandedClient] = React.useState(true);
   const [expandedTransporter, setExpandedTransporter] = React.useState(false);
   const [expandedTransport, setExpandedTransport] = React.useState(false);


   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };
   const handleChange2 = (panel) => (event, newExpanded) => {
      setExpanded2(newExpanded ? panel : false);
   };
   const handleChangeClient = (panel) => (event, newExpanded) => {
      setExpandedClient(!expandedClient)
   };
   const handleChangeTransporter = (panel) => (event, newExpanded) => {
      setExpandedTransporter(!expandedTransporter)
   };

   const handleChangeTransportBlock = (panel) => (event, newExpanded) => {
      setExpandedTransport(!expandedTransport)
   };





   const [open1, setOpen1] = React.useState(false);
   const [open2, setOpen2] = React.useState(false);
   const [open3, setOpen3] = React.useState(false);
   const [open4, setOpen4] = React.useState(false);

   const [options1, setOptions1] = React.useState([]);
   const [options2, setOptions2] = React.useState([]);
   const [options3, setOptions3] = React.useState([]);
   const [options4, setOptions4] = React.useState([]);

   const loading1 = open1 && options1.length === 0;
   const loading2 = open2 && options2.length === 0;
   const loading3 = open3 && options3.length === 0;
   const loading4 = open4 && options4.length === 0;


   const { transporter1AdditionalExpensesSum, clientAdditionalExpensesSum, inspectionGeneralBlockInfo, dispatch } = useContext(ECContext)

   React.useEffect(() => {
      let active = true;

      if (!loading1) {
         return undefined;
      }

      (async () => {
         await sleep(1e3); // For demo purposes.

         if (active) {
            setOptions1([...topFilms]);
         }
      })();

      return () => {
         active = false;
      };
   }, [loading1]);

   React.useEffect(() => {
      let active = true;

      if (!loading2) {
         return undefined;
      }

      (async () => {
         await sleep(1e3); // For demo purposes.

         if (active) {
            setOptions2([...topFilms]);
         }
      })();

      return () => {
         active = false;
      };
   }, [loading2]);

   React.useEffect(() => {
      let active = true;

      if (!loading3) {
         return undefined;
      }

      (async () => {
         await sleep(1e3); // For demo purposes.

         if (active) {
            setOptions3([...topFilms]);
         }
      })();

      return () => {
         active = false;
      };
   }, [loading3]);

   React.useEffect(() => {
      let active = true;

      if (!loading4) {
         return undefined;
      }

      (async () => {
         await sleep(1e3); // For demo purposes.

         if (active) {
            setOptions4([...topFilms]);
         }
      })();

      return () => {
         active = false;
      };
   }, [loading4]);

   const [showSegment2, setShowSegment2] = React.useState(false);
   const [showSegment3, setShowSegment3] = React.useState(false);
   const [showSegment4, setShowSegment4] = React.useState(false);

   const [showClient, setShowClient] = React.useState(true);

   const [segment1TransportType, setSegment1TransportType] = React.useState('');
   const [segment2TransportType, setSegment2TransportType] = React.useState('');
   const [segment3TransportType, setSegment3TransportType] = React.useState('');
   const [segment4TransportType, setSegment4TransportType] = React.useState('');


   const [totalClientSum, setTotalClientSum] = React.useState(0);
   const [totalTransporter1Sum, setTotalTransporter1Sum] = React.useState(0);
   const onChangeTransportTypeSegment1 = (event, value) => {

      setSegment1TransportType(event.target.value)
   };



   const topFilms = [
      { title: 'The Shawshank Redemption', year: 1994 },
      { title: 'The Godfather', year: 1972 },
      { title: 'The Godfather: Part II', year: 1974 },
      { title: 'The Dark Knight', year: 2008 },
      { title: '12 Angry Men', year: 1957 },
      { title: "Schindler's List", year: 1993 },
      { title: 'Pulp Fiction', year: 1994 },
      {
         title: 'The Lord of the Rings: The Return of the King',
         year: 2003,
      },
      { title: 'The Good, the Bad and the Ugly', year: 1966 },
      { title: 'Fight Club', year: 1999 },
      {
         title: 'The Lord of the Rings: The Fellowship of the Ring',
         year: 2001,
      },
      {
         title: 'Star Wars: Episode V - The Empire Strikes Back',
         year: 1980,
      },
      { title: 'Forrest Gump', year: 1994 },
      { title: 'Inception', year: 2010 },
      {
         title: 'The Lord of the Rings: The Two Towers',
         year: 2002,
      },
      { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
      { title: 'Goodfellas', year: 1990 },
      { title: 'The Matrix', year: 1999 },
      { title: 'Seven Samurai', year: 1954 },
      {
         title: 'Star Wars: Episode IV - A New Hope',
         year: 1977,
      },
      { title: 'City of God', year: 2002 },
      { title: 'Se7en', year: 1995 },
      { title: 'The Silence of the Lambs', year: 1991 },
      { title: "It's a Wonderful Life", year: 1946 },
      { title: 'Life Is Beautiful', year: 1997 },
      { title: 'The Usual Suspects', year: 1995 },
      { title: 'Léon: The Professional', year: 1994 },
      { title: 'Spirited Away', year: 2001 },
      { title: 'Saving Private Ryan', year: 1998 },
      { title: 'Once Upon a Time in the West', year: 1968 },
      { title: 'American History X', year: 1998 },
      { title: 'Interstellar', year: 2014 },
   ];




   const onShowSegment2 = (event, value) => {
      setShowSegment2(true);
   };
   const onRemoveSegment2 = (event, value) => {
      setShowSegment2(false);
   };


   const onShowSegment3 = (event, value) => {
      setShowSegment3(true);
   };
   const onRemoveSegment3 = (event, value) => {
      setShowSegment3(false);
   };

   const onShowSegment4 = (event, value) => {
      setShowSegment4(true);
   };
   const onRemoveSegment4 = (event, value) => {
      setShowSegment4(false);
   };

   const defaultProps = {
      options: topFilms,
      getOptionLabel: (option) => option.title,
   };


   const [showCustomFromInSegment1, setShowCustomFromInSegment1] = React.useState(false);
   const [showCustomToInSegment1, setShowCustomToInSegment1] = React.useState(false);

   const onShowCustomFromInSegment1 = (event, value) => {
      setShowCustomFromInSegment1(!showCustomFromInSegment1)
   };

   const onShowCustomToInSegment1 = (event, value) => {
      setShowCustomToInSegment1(!showCustomToInSegment1)
   };

   const currencies = [
      {
         value: 'BYN',
         label: 'BYN',
      },
      {
         value: 'RUB',
         label: '₽',
      },
      {
         value: 'USD',
         label: '$',
      },
      {
         value: 'EUR',
         label: '€',
      },
      {
         value: 'JPY',
         label: '¥',
      },
   ];

   const [showSumDevider, setShowSumDevider] = React.useState(false);
   const onShowRequestSumDevider = (event, value) => {
      setShowSumDevider(!showSumDevider)
   };

   const [showAddExpences, setShowAddExpences] = React.useState(false);
   const [showAddExpencesTransporter, setShowAddExpencesTransporter] = React.useState(false);
   const onShowAddExpences = (event, value) => {
      setShowAddExpences(!showAddExpences)
   };


   const [showSumTransporterDevider, setShowSumTransporterDevider] = React.useState(false);
   const [contractBalance, setContractBalance] = React.useState(0);
   const onShowRequestSumTransporterDevider = (event, value) => {
      setShowSumTransporterDevider(!showSumTransporterDevider)
   };


   const [showCargoPrice, setShowCargoPrice] = React.useState(false);

   const onShowCargoPrice = (event, value) => {
      setShowCargoPrice(!showCargoPrice)
   };


   const openNewTransporterDialog = (event) => {
      const Id = event.target.parentElement.id

      dispatch({ type: 'SET_SHOW_NEW_TRANSPORTER_DIALOG', payload: {showDialog: true} })
   }

   const openNewTransporterContractDialog = (event) => {
      const Id = event.target.parentElement.id

      dispatch({ type: 'SET_SHOW_NEW_TRANSPORTER_CONTRACT_DIALOG', payload: {showDialog: true} })
   }

   const openNewClientContractDialog = (event) => {
      const Id = event.target.parentElement.id

      dispatch({ type: 'SET_SHOW_NEW_CLIENT_CONTRACT_DIALOG', payload: {showDialog: true} })
   }

// Автоматический перерасчёт баланса контракта
   React.useEffect(() => {

console.log(`We are in useEffect. totalClientSum: ${totalClientSum} clientAdditionalExpensesSum: ${clientAdditionalExpensesSum} 
totalTransporter1Sum: ${totalTransporter1Sum} transporter1AdditionalExpensesSum: ${transporter1AdditionalExpensesSum}`)

      const res = Number(totalClientSum) + Number(clientAdditionalExpensesSum) - Number(totalTransporter1Sum) - (Number.isNaN(transporter1AdditionalExpensesSum) ? 0 : Number(transporter1AdditionalExpensesSum) )

console.log('res: '+res)

      setContractBalance(res)
   }, [totalClientSum, clientAdditionalExpensesSum, totalTransporter1Sum, transporter1AdditionalExpensesSum]);






   return (

      <>

         <Stack direction="column" spacing={1} paddingY={1} sx={{width: '100%'}}>


{/*Клиент*/}
            <Stack direction="row" spacing={1} paddingY={1} sx={{display: showClient === true ? 'flex' : 'none'}}>

               <Accordion sx={{width: '100%'}} expanded={expandedClient} onChange={handleChangeClient()}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="client-content"
                     id="client-header"
                  >
                     <Typography>ИНФОРМАЦИЯ О КЛИЕНТЕ</Typography>
                  </AccordionSummary>
                  <AccordionDetails>


                     <Stack direction="row" spacing={1} paddingY={1} >

                        {/*Клиент*/}
                        <Autocomplete
                           fullWidth
                           {...defaultProps}
                           id="client-select"
                           blurOnSelect
                           renderInput={(params) => (
                              <TextField {...params} label="Клиент" />
                           )}
                        />
                        {/*                        <IconButton color="primary" aria-label="" component="label" value={1} disabled={true} ><AddIcon /></IconButton>*/}

                        {/*Контракт с клиентом*/}
                        <Autocomplete
                           fullWidth
                           {...defaultProps}
                           id="client-contract-select"
                           blurOnSelect
                           renderInput={(params) => (
                              <TextField {...params} label="Контракт" />
                           )}
                        />
                        {/*                        <IconButton color="primary" aria-label="" component="label" value={1} onClick={openNewClientContractDialog} ><AddIcon /></IconButton>*/}

                        {/*Ответственный*/}
                        <Autocomplete
                           fullWidth
                           {...defaultProps}
                           id="request_responsible"
                           sx={{width: "30%"}}
                           blurOnSelect
                           renderInput={(params) => (
                              <TextField {...params} label="Ответственный" />
                           )}
                        />

                     </Stack>


                     <Stack direction="row" spacing={1} paddingY={1} >
                     </Stack>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                        <FormControl sx={{width: "20%"}}>
                           <InputLabel id="adr-select-label">Сроки оплаты</InputLabel>
                           <Select
                              fullWidth
                              labelId="payment-date-select-label"
                              id="payment-date-select"
                              label="Сроки оплаты"
                           >
                              <MenuItem value={1}>Отсрочка</MenuItem>
                              <MenuItem value={2}>Предоплата</MenuItem>
                              <MenuItem value={3}>Оплата частями</MenuItem>
                           </Select>
                        </FormControl>

                        <TextField
                           id="request_notes"
                           label="Условия оплаты"
                           //fullWidth
                           sx={{width: "40%"}}
                           multiline
                           rows={1}
                        />
                        <TextField
                           id="request_other_notes"
                           label="Другое"
                           sx={{width: "40%"}}
                           multiline
                           rows={1}
                        />


{/*
                        <FormGroup sx={{width: "150px"}}>
                           <FormControlLabel control={<Checkbox />} label="Делим на части" value={showSumDevider} onClick={onShowRequestSumDevider} />
                        </FormGroup>
*/}

                     </Stack>

{/*
                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' sx={{display: showSumDevider === true ? 'flex' : 'none'}}>
                        <TextField
                           id="sum_devider_details"
                           label="Детали схемы оплаты"
                           fullWidth
                           multiline
                           rows={3}
                        />
                     </Stack>
*/}



                     {/*Сумма*/}
                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                        <TextField
                           id="sum_contract"
                           label="Сумма общая"
                           type="number"
                           InputLabelProps={{
                              shrink: true,
                           }}
                           value={totalClientSum}
                           onChange={(event) => {setTotalClientSum(event.target.value)}}
                        />
                        <TextField id="request-currency" select label="Валюта заявки" defaultValue="RUB" helperText="" sx={{width: "150px"}}>
                           {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                 {option.label}
                              </MenuItem>
                           ))}
                        </TextField>
                        <TextField id="request-payment-currency" select label="Валюта оплаты" defaultValue="USD" helperText="" sx={{width: "150px"}}>
                           {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                 {option.label}
                              </MenuItem>
                           ))}
                        </TextField>
                        <FormGroup sx={{width: "250px"}}>
                           <FormControlLabel control={<Checkbox />} label="Есть доп. расходы" value={showAddExpences} onClick={onShowAddExpences} />
                        </FormGroup>


                     </Stack>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' sx={{display: showAddExpences === true ? 'flex' : 'none'}}>

                        <AdditionalExpences />
                     </Stack>


                     {/*Баланс по сделке*/}
                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                        <TextField
                           disabled={true}
                           id="contract_balance"
                           label="Баланс по сделке"
                           type="number"
                           value={contractBalance}
                           error={contractBalance < 0}

                        />
                     </Stack>



                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
{/*
                        <TextField
                           id="sum_additional_cost"
                           label="Сумма доп. расходов"
                           type="number"
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />
*/}

{/*
                        <TextField id="request-currency" select label="Валюта доп. расходов" defaultValue="RUB" helperText="" sx={{width: "150px"}}>
                           {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                 {option.label}
                              </MenuItem>
                           ))}
                        </TextField>
*/}
                     </Stack>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                     </Stack>



                  </AccordionDetails>
               </Accordion>
            </Stack>

{/*Сегмент 1*/}
            <Stack direction="row" spacing={1} paddingY={1} >

               <Accordion sx={{width: '100%'}} expanded={expanded === 'panel_segment1'} onChange={handleChange('panel_segment1')}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="segment1-content"
                  id="segment1-header"
               >
                  <Typography>СЕГМЕНТ 1</Typography>
               </AccordionSummary>
               <AccordionDetails>

                  <Stack direction="row" spacing={1} paddingY={1} alignItems='center'>

                     <Autocomplete
                        id="asynchronous-demo"
                        multiple
                        sx={{ width: '100%' }}
                        open={open1}
                        onOpen={() => {
                           setOpen1(true);
                        }}
                        onClose={() => {
                           setOpen1(false);
                        }}
                        isOptionEqualToValue={(option1, value) => option1.title === value.title}
                        getOptionLabel={(option1) => option1.title}
                        options={options1}
                        loading={loading1}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Пункты загрузки сегмента (откуда)"
                              InputProps={{
                                 ...params.InputProps,
                                 endAdornment: (
                                    <React.Fragment>
                                       {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                       {params.InputProps.endAdornment}
                                    </React.Fragment>
                                 ),
                              }}
                           />
                        )}
                     />

                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Дата загрузки (c)"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Дата загрузки (по)"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>

                     <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Таможня" value={showCustomFromInSegment1} onClick={onShowCustomFromInSegment1} />
                     </FormGroup>

                  </Stack>

                  {/*Таможня отправления*/}
                  <Stack direction="row" spacing={1} paddingY={1} sx={{display: showCustomFromInSegment1 === true ? 'flex' : 'none'}}>

                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Таможня отправления. Дата (план)"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Таможня отправления. Дата (факт)"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>

                     <Autocomplete
                        id="asynchronous-demo"
                        multiple
                        sx={{ width: '100%' }}
                        open={open1}
/*
                        onOpen={() => {
                           setOpen1(true);
                        }}
*/
                        onClose={() => {
                           setOpen1(false);
                        }}
                        isOptionEqualToValue={(option1, value) => option1.title === value.title}
                        getOptionLabel={(option1) => option1.title}
                        options={options1}
                        loading={loading1}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Таможня отправляения"
                              InputProps={{
                                 ...params.InputProps,
                                 endAdornment: (
                                    <React.Fragment>
                                       {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                       {params.InputProps.endAdornment}
                                    </React.Fragment>
                                 ),
                              }}
                           />
                        )}
                     />


                  </Stack>


                  <Stack direction="row" spacing={1} paddingY={1} alignItems='center'>

                     <Autocomplete
                        id="asynchronous-demo"
                        multiple
                        sx={{ width: '100%' }}
                        open={open1}
                        onOpen={() => {
                           setOpen2(true);
                        }}
                        onClose={() => {
                           setOpen2(false);
                        }}
                        isOptionEqualToValue={(option2, value) => option2.title === value.title}
                        getOptionLabel={(option2) => option2.title}
                        options={options2}
                        loading={loading2}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Пункты выгрузкы сегмента (куда)"
                              InputProps={{
                                 ...params.InputProps,
                                 endAdornment: (
                                    <React.Fragment>
                                       {loading2 ? <CircularProgress color="inherit" size={20} /> : null}
                                       {params.InputProps.endAdornment}
                                    </React.Fragment>
                                 ),
                              }}
                           />
                        )}
                     />

                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Дата выгрузки (планируемая) c"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Дата выгрузки (планируемая) по"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>

                     <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Таможня" value={showCustomToInSegment1} onClick={onShowCustomToInSegment1} />
                     </FormGroup>


                  </Stack>

                  {/*Таможня назначения. Дата (план)*/}
                  <Stack direction="row" spacing={1} paddingY={1} sx={{display: showCustomToInSegment1 === true ? 'flex' : 'none'}}>

                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Таможня назначения. Дата (план)"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Таможня назначения. Дата (факт)"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>

                     <Autocomplete
                        id="asynchronous-demo"
                        multiple
                        sx={{ width: '100%' }}
                        open={open1}
                        /*
                                                onOpen={() => {
                                                   setOpen1(true);
                                                }}
                        */
                        onClose={() => {
                           setOpen1(false);
                        }}
                        isOptionEqualToValue={(option1, value) => option1.title === value.title}
                        getOptionLabel={(option1) => option1.title}
                        options={options1}
                        loading={loading1}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Таможня назначения"
                              InputProps={{
                                 ...params.InputProps,
                                 endAdornment: (
                                    <React.Fragment>
                                       {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                       {params.InputProps.endAdornment}
                                    </React.Fragment>
                                 ),
                              }}
                           />
                        )}
                     />


                  </Stack>

                  {/*Перевозчик*/}
                  <Stack direction="row" spacing={1} paddingY={1} >

                     <Accordion sx={{width: '100%'}} expanded={expandedTransporter} onChange={handleChangeTransporter()}>
                        <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="transporter-block-content"
                           id="transporter-block-header"
                        >
                           <Typography>Перевозчик</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                           {/*Перевозчик*/}
                           <Stack direction="row" spacing={1} paddingY={1} >
                              <Autocomplete
                                 fullWidth
                                 {...defaultProps}
                                 id="blur-on-select"
                                 blurOnSelect
                                 renderInput={(params) => (
                                    <TextField {...params} label="Перевозчик" />
                                 )}
                              />
                              {/*<IconButton color="primary" aria-label="" component="label" value={1} onClick={openNewTransporterDialog} ><AddIcon /></IconButton>*/}


                              {/*Контракт с перевозчиком*/}
                              <Autocomplete
                                 fullWidth
                                 {...defaultProps}
                                 id="transpoerter-contract-select"
                                 blurOnSelect
                                 renderInput={(params) => (
                                    <TextField {...params} label="Контракт c перевозчиком" />
                                 )}
                              />
                              {/*<IconButton color="primary" aria-label="" component="label" value={1} onClick={openNewTransporterContractDialog} ><AddIcon /></IconButton>*/}

                              <TextField sx={{width: "30%"}} id="segment_responsible" label="Ответственный по сегменту"/>
                           </Stack>

                           <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                              <FormControl sx={{width: "20%"}}>
                                 <InputLabel id="adr-select-label">Сроки оплаты перевозчику</InputLabel>
                                 <Select
                                    fullWidth
                                    labelId="payment-date-select-label"
                                    id="payment-date-select"
                                    label="Сроки оплаты перевозчику"
                                 >
                                    <MenuItem value={1}>Отсрочка</MenuItem>
                                    <MenuItem value={2}>Предоплата</MenuItem>
                                    <MenuItem value={3}>Оплата частями</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="request_notes"
                                 label="Условия оплаты перевозчику"
                                 //fullWidth
                                 sx={{width: "40%"}}
                                 multiline
                                 rows={1}
                              />
                              <TextField
                                 id="request_other_notes"
                                 label="Другое"
                                 sx={{width: "40%"}}
                                 multiline
                                 rows={1}
                              />


{/*
                              <FormGroup>
                                 <FormControlLabel control={<Checkbox />} label="Делим на части" value={showSumTransporterDevider} onClick={onShowRequestSumTransporterDevider} />
                              </FormGroup>
*/}

                           </Stack>

{/*
                           <Stack direction="row" spacing={1} paddingY={1} alignItems='center' sx={{display: showSumTransporterDevider === true ? 'flex' : 'none'}}>
                              <TextField
                                 id="sum_devider_details"
                                 label="Детали расчёта с перевозчиком"
                                 fullWidth
                                 multiline
                                 rows={3}
                              />
                           </Stack>
*/}


                           {/*Сумма (перевозчиком)*/}
                           <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                              <TextField
                                 id="sum_contract_transporter"
                                 label="Сумма общая"
                                 type="number"
                                 InputLabelProps={{
                                    shrink: true,
                                 }}
                                 value={totalTransporter1Sum}
                                 onChange={(event) => {setTotalTransporter1Sum(event.target.value)}}
                              />
                              <TextField id="request-currency" select label="Валюта заявки перевозчику" defaultValue="RUB" helperText="" sx={{width: "150px"}}>
                                 {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </TextField>
                              <TextField id="request-payment-currency" select label="Валюта оплаты перевозчику" defaultValue="USD" helperText="" sx={{width: "150px"}}>
                                 {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </TextField>

                              <FormGroup sx={{width: "250px"}}>
                                 <FormControlLabel control={<Checkbox />} label="Есть доп. расходы" value={showAddExpencesTransporter}
                                                   onClick={(event, value) => {setShowAddExpencesTransporter(!showAddExpencesTransporter)}}
                                 />
                              </FormGroup>


                           </Stack>

                           <Stack direction="row" spacing={1} paddingY={1} alignItems='center' sx={{display: showAddExpencesTransporter === true ? 'flex' : 'none'}}>

                              <AdditionalExpencesTransporter1 />
                           </Stack>



                           <Stack direction="row" spacing={1} paddingY={1}>
                              <TextField fullWidth id="transporter_TC" label="№ ТС перевозчика" sx={{width: '300px'}} />
                              <TextField fullWidth id="driver_data" label="Данные по водителю"/>
                           </Stack>


                        </AccordionDetails>
                     </Accordion>
                  </Stack>

                  {/*Траспорт*/}
                  <Stack direction="row" spacing={1} paddingY={1} >

                     <Accordion sx={{width: '100%'}} expanded={expandedTransport} onChange={handleChangeTransportBlock()}>
                        <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="transport-block-content"
                           id="transport-block-header"
                        >
                           <Typography>Транспорт</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                           <Stack direction="column" spacing={1} paddingY={1} sx={{width: "100%"}}>

                              <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
                                 <FormControl fullWidth>
                                    <InputLabel id="transport-type-select-label">Вид траспорта на сегменте</InputLabel>
                                    <Select
                                       sx={{width: "300px"}}
                                       labelId="transport-type-select-label"
                                       id="transport-type-select"
                                       label="Вид траспорта на сегменте"
                                       onChange={onChangeTransportTypeSegment1}
                                    >
                                       <MenuItem value={1}>Авто</MenuItem>
                                       <MenuItem value={2}>Ж/Д</MenuItem>
                                       <MenuItem value={3}>Морской</MenuItem>
                                       <MenuItem value={4}>Авиа</MenuItem>
                                    </Select>
                                 </FormControl>
                              </Stack>

                              {/*Авто*/}
                              <Stack direction="column" spacing={1} paddingY={1} sx={{display: segment1TransportType === 1 ? 'flex' : 'none'}}>

                                 <TransportAuto />
                              </Stack>

                              {/*ЖД*/}
                              <Stack direction="column" spacing={1} paddingY={1} sx={{display: segment1TransportType === 2 ? 'flex' : 'none'}}>

                                 <TransportZhd />
                              </Stack>

                              {/*Морской*/}
                              <Stack direction="column" spacing={1} paddingY={1} sx={{display: segment1TransportType === 3 ? 'flex' : 'none'}}>

                                 <TransportSea />
                              </Stack>

                              {/*Авиа*/}
                              <Stack direction="column" spacing={1} paddingY={1} sx={{display: segment1TransportType === 4 ? 'flex' : 'none'}}>

                                 <TransportAvia />
                              </Stack>


                           </Stack>

                        </AccordionDetails>
                     </Accordion>
                  </Stack>






               </AccordionDetails>
            </Accordion>
               <IconButton color="primary" aria-label="" component="label" value={1} onClick={onShowSegment2}>
                  <AddIcon />
               </IconButton>
            </Stack>

{/*Сегмент 2*/}
            <Stack direction="row" spacing={1} paddingY={1} sx={{display: showSegment2 === true ? 'flex' : 'none'}}>

               <Accordion sx={{width: '100%'}} expanded={expanded === 'panel_segment2'} onChange={handleChange('panel_segment2')}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="segment2-content"
                     id="segment2-header"
                  >
                     <Typography>СЕГМЕНТ 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>


                  </AccordionDetails>
               </Accordion>
               <IconButton color="primary" aria-label="" component="label" value={1} onClick={onShowSegment3}>
                  <AddIcon />
               </IconButton>
               <IconButton color="primary" aria-label="" component="label" value={1} onClick={onRemoveSegment2}>
                  <RemoveIcon />
               </IconButton>
            </Stack>

{/*Сегмент 3*/}
            <Stack direction="row" spacing={1} paddingY={1} sx={{display: showSegment3 === true ? 'flex' : 'none'}}>

               <Accordion sx={{width: '100%'}} expanded={expanded === 'panel_segment3'} onChange={handleChange('panel_segment3')}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="segment2-content"
                     id="segment2-header"
                  >
                     <Typography>СЕГМЕНТ 3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>


                  </AccordionDetails>
               </Accordion>
               <IconButton color="primary" aria-label="upload picture" component="label" value={1} onClick={onShowSegment4}>
                  <AddIcon />
               </IconButton>
               <IconButton color="primary" aria-label="" component="label" value={1} onClick={onRemoveSegment3}>
                  <RemoveIcon />
               </IconButton>

            </Stack>

{/*Сегмент 4*/}
            <Stack direction="row" spacing={1} paddingY={1} sx={{display: showSegment4 === true ? 'flex' : 'none'}}>

               <Accordion sx={{width: '100%'}} expanded={expanded === 'panel_segment4'} onChange={handleChange('panel_segment4')}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="segment2-content"
                     id="segment2-header"
                  >
                     <Typography>СЕГМЕНТ 4</Typography>
                  </AccordionSummary>
                  <AccordionDetails>


                  </AccordionDetails>
               </Accordion>
               <IconButton color="primary" aria-label="" component="label" value={1} onClick={onRemoveSegment4}>
                  <RemoveIcon />
               </IconButton>
            </Stack>


{/*Cargo section*/}
            <Accordion expanded={expanded === 'panel_segment_cargo'} onChange={handleChange('panel_segment_cargo')}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="segment-cargo-content"
                  id="segment-cargo-header"
               >
                  <Typography>ГРУЗ</Typography>
               </AccordionSummary>
               <AccordionDetails>


                  <Stack direction="row" spacing={1} paddingY={1}>
                     <TextField fullWidth id="cargo-name" label="Наименование груза"/>
                  </Stack>

                  <Stack direction="row" spacing={1} paddingY={1}>

                     <Palletes/>
                     <Cargos />
                  </Stack>





                  <Stack direction="column" spacing={1} paddingY={1} sx={{width: "50%"}}>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='left' justifyContent="center" >

                        <FormControl>
                           <InputLabel id="adr-select-label">ADR</InputLabel>
                           <Select
                              /*fullWidth*/
                              sx={{width: "200px"}}
                              labelId="adr-select-label"
                              id="adr-select"
                              label="ADR"
                           >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                           </Select>
                        </FormControl>

                        <FormGroup>
                           <FormControlLabel control={<Checkbox />} label="Стоимость груза" value={showCargoPrice} onClick={onShowCargoPrice} />
                        </FormGroup>

                        <FormGroup>
                           <FormControlLabel control={<Checkbox />} label="Доп. страхование груза" />
                        </FormGroup>


                     </Stack>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='left' >
                     </Stack>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='left' sx={{display: showCargoPrice === true ? 'flex' : 'none'}}>
                        <TextField
                           id="sum_contract"
                           label="Стоимость груза"
                           type="number"
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />
                        <TextField id="cargo-price-currency" select label="Валюта" defaultValue="RUB" helperText="" sx={{width: "150px"}}>
                           {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                 {option.label}
                              </MenuItem>
                           ))}
                        </TextField>


                     </Stack>

                     <Stack direction="row" spacing={1} paddingY={1} alignItems='left' >
                     </Stack>


                  </Stack>





               </AccordionDetails>
            </Accordion>





         </Stack>



         <NewTransporter />
         <NewClientContract />
         <NewTransporterContract />



      </>

   );
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ECContext from "../../context/inspections/ECContext";
import {useContext, useEffect} from "react";
import {DialogActions, DialogContent, DialogTitle, Slide, Stack, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewClientContract() {
   const [open, setOpen] = React.useState(false);
   const { showNewClientContractDialog, dispatch } = useContext(ECContext)

   useEffect(() => {

      console.log(`we are in the Dialog (useEffect)`)
      setOpen(showNewClientContractDialog.showDialog)

   }, [showNewClientContractDialog]);

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   return (
      <div>
         <Dialog
            open={open}
            onClose={handleClose}
         >
            <DialogTitle>Новый контракт с клиентом</DialogTitle>
            <DialogContent>

               <Stack direction="column" spacing={1} paddingY={1} sx={{width: '100%'}}>

                  <Stack direction="row" spacing={1} padding={1}>
                     <TextField fullWidth id="transporter_contruct_number" label="№ контракта" sx={{width: '200px'}} />
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                           label="Дата контракта"
                           value={0}
                           onChange={(newValue) => {
                              return 0;
                              //setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Stack>


               </Stack>

            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Отмена</Button>
               <Button onClick={handleClose}>Сохранить</Button>
            </DialogActions>
         </Dialog>








      </div>
   );
}
import * as React from 'react';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, useTheme} from "@mui/material";
import Box from "@mui/material/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

const names = [
   'Медкнижка',
   'Под там. контр.',
   'CMR',
   'CMR-страхование',
   'T1',
   'TIR'
];

function getStyles(name, personName, theme) {
   return {
      fontWeight:
         personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
   };
}


export default function TransportSea() {


   const theme = useTheme();
   const [allowNames, setallowNames] = React.useState([]);
   const [sostavType, setSostavType] = React.useState('');

   const handleChange = (event) => {
      const {
         target: { value },
      } = event;
      setallowNames(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value,
      );
   };




/*

Поле "Тип контейнера" (взять их в интернете) и их сокращения

20"
40" – в таком формате.
40" OT (open talk)
?Flat track
Tank
Реф.
RO-RO
*/



   return (
      <>

         <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
            <FormControl>
               <InputLabel id="transport-type-select-label">Тип контейнера</InputLabel>
               <Select
                  sx={{width: "300px"}}
                  labelId="transport-type-kuzov-select-label"
                  id="transport-type-kuzov-select"
                  label="Тип контейнера"
                  value={sostavType}
                  onChange={(event) => {
                     setSostavType(event.target.value)
                  }}

               >
                  <MenuItem value={1}>20"</MenuItem>
                  <MenuItem value={2}>40"</MenuItem>
                  <MenuItem value={3}>40" OT</MenuItem>
                  <MenuItem value={4}>Flat track</MenuItem>
                  <MenuItem value={5}>Tank</MenuItem>
                  <MenuItem value={6}>Реф.</MenuItem>
                  <MenuItem value={7}>RO-RO</MenuItem>
               </Select>
            </FormControl>

            <TextField fullWidth id="kol-vagonov" label="Кол-во контейнеров" sx={{width: "150px"}}/>

            <Box sx={{display: sostavType == 6 ? 'flex' : 'none'}}>
               <TextField sx={{width: "600px"}} id="temp-conditions" label="Температурный режим" sx={{width: "150px"}} />
            </Box>
         </Stack>








      <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
      </Stack>

      </>

   );
}
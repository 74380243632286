import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ECContext from "../../context/inspections/ECContext";
import {useContext, useEffect} from "react";
import {Stack} from "@mui/material";

export default function NewTransporter() {
   const [open, setOpen] = React.useState(false);
   const { showNewTransporterDialog, dispatch } = useContext(ECContext)

   useEffect(() => {

      console.log(`we are in the Dialog (useEffect)`)
      setOpen(showNewTransporterDialog.showDialog)

   }, [showNewTransporterDialog]);

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   return (
      <div>
         <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
         >
            <AppBar sx={{ position: 'relative' }}>
               <Toolbar>
                  <IconButton
                     edge="start"
                     color="inherit"
                     onClick={handleClose}
                     aria-label="close"
                  >
                     <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                     Новый перевозчик
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handleClose}>
                     Сохранить
                  </Button>
               </Toolbar>
            </AppBar>

            <Stack direction="column" spacing={1} paddingY={1} sx={{width: '100%'}}>
               Данные по новому перевозчику
            </Stack>


         </Dialog>
      </div>
   );
}
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
   GridRowModes,
   DataGrid,
   GridToolbarContainer,
   GridActionsCellItem,
} from '@mui/x-data-grid';
import {
   randomCreatedDate,
   randomTraderName,
   randomUpdatedDate,
   randomId,
} from '@mui/x-data-grid-generator';

const initialRows = [
   {
      id: randomId(),
      length: 10,
      width: 2,
      height: 5,
      weight: 300,
      volume: 77,
      comment: "необязательный комментарий"
   },
   {
      id: randomId(),
      length: 8,
      width: 9,
      height: 3,
      weight: 218,
      volume: 52,
      comment: "необязательный комментарий"
   },
];

function EditToolbar(props) {
   const { setRows, setRowModesModel } = props;

   const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { id, length: '', width: '', height: '', weight: '', volume: '', comment: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
         ...oldModel,
         [id]: { mode: GridRowModes.Edit, fieldToFocus: 'length' },
      }));
   };

   return (
      <GridToolbarContainer>
         <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Добавить Груз
         </Button>
      </GridToolbarContainer>
   );
}

EditToolbar.propTypes = {
   setRowModesModel: PropTypes.func.isRequired,
   setRows: PropTypes.func.isRequired,
};

export default function Cargos() {
   const [rows, setRows] = React.useState(initialRows);
   const [rowModesModel, setRowModesModel] = React.useState({});

   const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
   };

   const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true;
   };

   const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
   };

   const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
   };

   const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
   };

   const handleCancelClick = (id) => () => {
      setRowModesModel({
         ...rowModesModel,
         [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
         setRows(rows.filter((row) => row.id !== id));
      }
   };

   const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
   };


   const columns = [
      { field: 'length', headerName: 'Д,м', type: 'number', width: 50, editable: true },
      { field: 'width', headerName: 'Ш,м', type: 'number',  width: 50, editable: true },
      { field: 'height', headerName: 'В,м', type: 'number',  width: 50, editable: true },
      { field: 'weight', headerName: 'm,Т', type: 'number',  width: 50, editable: true },
      { field: 'volume', headerName: 'V,м3', type: 'number',  width: 50, editable: true },
      { field: 'comment', headerName: 'Комментарий', width: '600', editable: true },
      {
         field: 'actions',
         type: 'actions',
         headerName: '',
         width: 100,
         cellClassName: 'actions',
         getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
               return [
                  <GridActionsCellItem
                     icon={<SaveIcon />}
                     label="Сохранить"
                     onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                     icon={<CancelIcon />}
                     label="Отмена"
                     className="textPrimary"
                     onClick={handleCancelClick(id)}
                     color="inherit"
                  />,
               ];
            }

            return [
               <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
               />,
               <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
               />,
            ];
         },
      },
   ];

   return (
      <Box
         sx={{
            height: 250,
            width: '80%',
            '& .actions': {
               color: 'text.secondary',
            },
            '& .textPrimary': {
               color: 'text.primary',
            },
         }}
      >
         <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
               Toolbar: EditToolbar,
            }}
            componentsProps={{
               toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}

            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter={true}
         />
      </Box>
   );
}
import * as React from 'react';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, useTheme} from "@mui/material";
import Box from "@mui/material/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

const names = [
   'Медкнижка',
   'Под там. контр.',
   'CMR',
   'CMR-страхование',
   'T1',
   'TIR'
];

function getStyles(name, personName, theme) {
   return {
      fontWeight:
         personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
   };
}


export default function TransportAvia() {


   const theme = useTheme();
   const [allowNames, setallowNames] = React.useState([]);
   const [sostavType, setSostavType] = React.useState('');

   const handleChange = (event) => {
      const {
         target: { value },
      } = event;
      setallowNames(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value,
      );
   };




/*
Тип "Обычный", "Температурный режим" (тогда указываем диапазон)
Тип рейса: "прямой", "не прямой"

*/



   return (
      <>

         <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
            <FormControl>
               <InputLabel id="transport-type-select-label">Тип рейса</InputLabel>
               <Select
                  sx={{width: "300px"}}
                  labelId="transport-type-kuzov-select-label"
                  id="transport-type-kuzov-select"
                  label="Тип рейса"
/*
                  value={sostavType}
                  onChange={(event) => {
                     setSostavType(event.target.value)
                  }}
*/

               >
                  <MenuItem value={1}>Прямой"</MenuItem>
                  <MenuItem value={2}>Непрямой</MenuItem>
               </Select>
            </FormControl>

            <TextField sx={{width: "600px"}} id="temp-conditions" label="Температурный режим" sx={{width: "150px"}} />

         </Stack>








      <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
      </Stack>

      </>

   );
}
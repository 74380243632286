import * as React from 'react';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, useTheme} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

const names = [
   'Медкнижка',
   'Под там. контр.',
   'CMR',
   'CMR-страхование',
   'T1',
   'TIR'
];

function getStyles(name, personName, theme) {
   return {
      fontWeight:
         personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
   };
}


export default function TransportAuto() {


   const theme = useTheme();
   const [allowNames, setallowNames] = React.useState([]);

   const handleChange = (event) => {
      const {
         target: { value },
      } = event;
      setallowNames(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value,
      );
   };






   return (
      <>

      <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
         <FormControl>
            <InputLabel id="transport-type-select-label">Тип кузова</InputLabel>
            <Select
               sx={{width: "300px"}}
               labelId="transport-type-kuzov-select-label"
               id="transport-type-kuzov-select"
               label="Тип кузова"
            >
               <MenuItem disabled={true} sx={{backgroundColor: 'gray', color: 'white' }} value={1}>Крытая</MenuItem>
               <MenuItem value={2}>Тент</MenuItem>
               <MenuItem value={3}>Цельномет</MenuItem>
               <MenuItem value={4}>Бус</MenuItem>
               <MenuItem value={5}>Контейнер</MenuItem>
               <MenuItem value={6}>Одеждовоз</MenuItem>
               <MenuItem value={7}>Изотерм</MenuItem>
               <MenuItem value={8}>Реф.</MenuItem>
               <MenuItem value={9}>Реф.-тушевоз</MenuItem>
               <MenuItem disabled={true} sx={{backgroundColor: 'gray', color: 'white' }} value={10}>Открытая</MenuItem>
               <MenuItem value={11}>Бортовая / Открытая</MenuItem>
               <MenuItem value={12}>Платформа</MenuItem>
               <MenuItem value={13}>Манипулятор</MenuItem>
               <MenuItem value={14}>Ломовоз / Металловоз</MenuItem>
               <MenuItem value={15}>Контейнеровоз</MenuItem>
               <MenuItem value={16}>Трал / Негабарит</MenuItem>
               <MenuItem value={17}>Плитовоз</MenuItem>
               <MenuItem value={18}>Самосвал</MenuItem>
               <MenuItem disabled={true} sx={{backgroundColor: 'gray', color: 'white' }} value={19}>Цистерна</MenuItem>
               <MenuItem value={20}>Молоковоз</MenuItem>
               <MenuItem value={21}>Битумовоз</MenuItem>
               <MenuItem value={22}>Бензовоз</MenuItem>
               <MenuItem value={23}>Газовоз</MenuItem>
               <MenuItem value={24}>Кормовоз</MenuItem>
               <MenuItem value={25}>Муковоз</MenuItem>
               <MenuItem value={26}>Автоцистерна</MenuItem>
               <MenuItem value={27}>Цементовоз</MenuItem>
               <MenuItem value={28}>Масловоз</MenuItem>
               <MenuItem disabled={true} sx={{backgroundColor: 'gray', color: 'white' }} value={29}>Специальная техника</MenuItem>
               <MenuItem value={30}>Автовоз</MenuItem>
               <MenuItem value={31}>Автовышка</MenuItem>
               <MenuItem value={32}>Бетоновоз</MenuItem>
               <MenuItem value={33}>Зерновоз</MenuItem>
               <MenuItem value={34}>Лесовоз</MenuItem>
               <MenuItem value={35}>Коневоз</MenuItem>
               <MenuItem value={36}>Кран</MenuItem>
               <MenuItem value={37}>Мусоровоз</MenuItem>
               <MenuItem value={38}>Погрузчик</MenuItem>
               <MenuItem value={39}>Птицевоз</MenuItem>
               <MenuItem value={40}>Скотовоз</MenuItem>
               <MenuItem value={41}>Стекловоз</MenuItem>
               <MenuItem value={42}>Трубовоз</MenuItem>
               <MenuItem value={43}>Тягач</MenuItem>
               <MenuItem value={44}>Щеповоз</MenuItem>
               <MenuItem value={45}>Эвакуатор</MenuItem>
               <MenuItem value={46}>Яхтовоз</MenuItem>
               <MenuItem disabled={true} sx={{backgroundColor: 'gray', color: 'white' }} value={47}>Пассажирский</MenuItem>
               <MenuItem value={48}>Микроавтобус</MenuItem>
               <MenuItem value={49}>Автобус</MenuItem>

            </Select>
         </FormControl>

         <FormControl>
            <InputLabel id="transport-type-select-label">Загрузка/Выгрузка</InputLabel>
            <Select
               sx={{width: "300px"}}
               labelId="transport-type-kuzov-select-label"
               id="transport-type-kuzov-select"
               label="Загрузка/Выгрузка"
            >
               <MenuItem value={1}>Со снятием поперечин</MenuItem>
               <MenuItem value={2}>Без ворот</MenuItem>
               <MenuItem value={3}>Со снятием стоек</MenuItem>
               <MenuItem value={4}>Верхняя</MenuItem>
               <MenuItem value={5}>Боковая</MenuItem>
               <MenuItem value={6}>Задняя</MenuItem>
               <MenuItem value={7}>С полной растентовкой</MenuItem>
               <MenuItem value={8}>Гидроборт</MenuItem>
            </Select>
         </FormControl>

         <FormControl>
            <InputLabel id="transport-type-select-label">Разрешения</InputLabel>

            <Select
               labelId="demo-multiple-name-label"
               id="demo-multiple-name"
               multiple
               value={allowNames}
               onChange={handleChange}
               input={<OutlinedInput label="Name" />}
               MenuProps={MenuProps}

               sx={{width: "300px"}}
            >
               {names.map((name) => (
                  <MenuItem
                     key={name}
                     value={name}
                     style={getStyles(name, allowNames, theme)}
                  >
                     {name}
                  </MenuItem>
               ))}
            </Select>
         </FormControl>


         {/*<TextField fullWidth id="vehicle-quantity" label="Кол-во авто" sx={{width: "150px"}}/>*/}

         <FormControl>
            <InputLabel id="transport-type-select-label"></InputLabel>
            <Select
               sx={{width: "300px"}}
               labelId="transport-type-kuzov-select-label"
               id="transport-type-kuzov-select"
               label=""
               sx={{width: "150px"}}
            >
               <MenuItem value={1}></MenuItem>
               <MenuItem value={2}>Разово</MenuItem>
               <MenuItem value={3}>В день</MenuItem>
               <MenuItem value={4}>В неделю</MenuItem>
               <MenuItem value={5}>В месяц</MenuItem>
            </Select>
         </FormControl>

      </Stack>




      <Stack direction="row" spacing={1} paddingY={1} alignItems='center' >
      </Stack>

      </>

   );
}